import * as React from "react";

import DestinationDescription from "components/flexComponents/DestinationDescription/DestinationDescription";
import { DestinationDescriptionViewProps } from "components/flexComponents/DestinationDescription/typings";
import { DestinationDescriptionFlexModuleResult } from "typings/microserviceModels/destination-description-flex-module";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { UitkUnformattedHtml, UitkUnformattedHtmlReplaceDefaults } from "@egds/react-core/unformatted-html";
import { UitkHeading, UitkHeadingProps } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";

const getHeading = (props: { children?: React.ReactNode }, headingConfig: UitkHeadingProps) => (
  <UitkSpacing margin={{ blockstart: "four" }}>
    <UitkHeading {...props} size={headingConfig.size} tag={headingConfig.tag}>
      {props.children}
    </UitkHeading>
  </UitkSpacing>
);

const getDescriptionBody = (body: string[]) => {
  const h3: UitkHeadingProps = { tag: "h3", size: 5 };
  const h4: UitkHeadingProps = { tag: "h4", size: 6 };
  const h5: UitkHeadingProps = { tag: "h5", size: 7 };

  return (
    <UitkUnformattedHtml
      elementsToReplace={{
        ...UitkUnformattedHtmlReplaceDefaults,
        h3: (props) => getHeading(props, h3),
        h4: (props) => getHeading(props, h4),
        h5: (props) => getHeading(props, h5),
      }}
      htmlToParse={body[0]}
    />
  );
};

const HtmlDestinationDescription = withStores("flexModuleModelStore")(
  observer((props: DestinationDescriptionViewProps) => {
    const { templateComponent, flexModuleModelStore } = props;
    const {
      metadata: { id },
    } = templateComponent;
    const model = flexModuleModelStore.getModel(id) as DestinationDescriptionFlexModuleResult | null;

    /* istanbul ignore next */
    if (!model?.content) {
      return null;
    }

    const title = model.content.title;

    return (
      <>
        {title && (
          <UitkHeading tag="h2" size={4}>
            {title}
          </UitkHeading>
        )}
        <DestinationDescription {...props} getDescriptionBody={getDescriptionBody} />
      </>
    );
  })
);

export default HtmlDestinationDescription;
